import { NameId } from "@common/types/NameId";
import { TFunction } from "next-i18next";

enum DealTypeEnum {
  Finance = 1,
  Lease = 2,
  Cash = 3,
  Aftermarket = 4,
  C2CFinancing = 5,
  BuyIn = 6,
}

export const dealTypes: NameId[] = [
  {
    name: "Finance",
    id: DealTypeEnum.Finance.toString(),
  },
  {
    name: "Lease",
    id: DealTypeEnum.Lease.toString(),
  },
  {
    name: "Cash",
    id: DealTypeEnum.Cash.toString(),
  },
  {
    name: "Aftermarket",
    id: DealTypeEnum.Aftermarket.toString(),
  },
  {
    name: "C2C Financing",
    id: DealTypeEnum.C2CFinancing.toString(),
  },
  {
    name: "Buy-In",
    id: DealTypeEnum.BuyIn.toString(),
  },
].sort((a, b) => a.name.localeCompare(b.name));

export function getDealTypeFilters(
  t: TFunction,
  showAllTypes: boolean,
  adminDealTypes?: NameId[]
): NameId[] {
  return [
    {
      name: t("financing", { ns: "common" }),
      id: DealTypeEnum.Finance.toString(),
    },
    {
      name: t("lease", { ns: "common" }),
      id: DealTypeEnum.Lease.toString(),
    },
    {
      name: t("cash", { ns: "common" }),
      id: DealTypeEnum.Cash.toString(),
    },
    {
      name: t("aftermarket", { ns: "admin" }),
      id: DealTypeEnum.Aftermarket.toString(),
    },
    {
      name: t("c2c", { ns: "admin" }),
      id: DealTypeEnum.C2CFinancing.toString(),
    },
    {
      name: t("buy_in", { ns: "admin" }),
      id: DealTypeEnum.BuyIn.toString(),
    },
  ]
    .filter(
      (dt) =>
        showAllTypes || adminDealTypes?.map((udt) => udt.id).includes(dt.id)
    )
    .sort((a, b) => a.name.localeCompare(b.name));
}

export default DealTypeEnum;
